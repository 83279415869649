// See .cache/page-templates after running dev/build
// to understand how this file ends up looking

/* eslint-disable no-unused-vars */
import React from 'react'
import { graphql, navigate } from 'gatsby'
import PageLayout from '../../src/layouts/PageLayout'
import ModalComponent from '../../src/components/Modal'

import BlockTitle from '../../src/components/ACF/BlockTitle'
import BlockForm from '../../src/components/ACF/BlockForm'

const PageTemplate = pageProps => {
	let data
data = pageProps.data.wpPage
	const componentsArray = data.page_components.components || []
	const components = componentsArray.map(component => {
		return {
			name: component.__typename.split('_').pop(),
			data: component
		}
	})

	return (
		<PageLayout
			pageData={data}
			state={pageProps.location.state}
			location={pageProps.location}
			prevPath={pageProps.path}
			pageTitle={data.title}
		>
			{/* <ModalComponent
				title="Beste bezoeker"
				content="Vanaf <b>19 Februari</b> t/m <b>23 Februari</b> zijn wij alleen aanwezig op afspraak. Telefonisch zijn we bereikbaar of via WhatsApp. <br/> <br/> Het ECU Adaptions team!"
				// content="ECU Adaptions is gesloten vanaf <b>maandag 25 December</b> tot <b>dinsdag 2 Januari</b>. Telefonisch zijn we bereikbaar of via WhatsApp. <br/> <br/>Hele fijne feestdagen namens het ECU Adaptions team!"
				emoji="📢 🚗 🚨"
			></ModalComponent> */}

			{components.map((component, index) => {

      if (component.name == 'BlockTitle') {
        return <BlockTitle {...component.data} key={index} />
      }
    

      if (component.name == 'BlockForm') {
        return <BlockForm {...component.data} key={index} />
      }
    
				return <div key={index}>Error: The component {component.name} was not found</div>
			})}
		</PageLayout>
	)
}

export default PageTemplate


    export const query = graphql`
      query PageQuery629($id: String!) {
        wpPage(id: {eq: $id}) {
					
		isFrontPage
		title
		header {
			
		heroChoice
		image {
			sourceUrl
			localFile {
				childImageSharp {
					gatsbyImageData(width: 1920, height: 800)
				}
			}
		}
		videoUrl
		height
		fieldGroupName
		backgroundOpacity
		backgroundColor
		pxOrVh
		heroText
  
		}
		seo {
			
		title
		metaDesc
		focuskw
		metaKeywords
		metaRobotsNoindex
		metaRobotsNofollow
		opengraphTitle
		opengraphDescription
		opengraphImage {
			altText
			sourceUrl
			srcSet
		}
		twitterTitle
		twitterDescription
		twitterImage {
			altText
			sourceUrl
			srcSet
		}
		canonical
		cornerstone
		schema {
			articleType
			pageType
			raw
		}
  
		}
  
          
    page_components {
      components {
        __typename
         
 
      ... on WpPage_PageComponents_Components_BlockAccordions {
        
		paddingBottom
		paddingTop
		accordions {
			content
			title
		}
  
      }
     
 
      ... on WpPage_PageComponents_Components_BlockAppointmentForm {
        
		paddingTop
		paddingBottom
  
      }
     
 
      ... on WpPage_PageComponents_Components_BlockBlog {
        
		buttonText
		link {
			url
			target
			title
		}
		showReadmoreButton
		maximumPostsCount
		paddingTop
		paddingBottom
	
      }
     
 
      ... on WpPage_PageComponents_Components_BlockCallToAction {
        
	callToAction{
		... on WpCta_post {
			id
			title
			postContent {
                buttonText
				imageOverlay
				imageOverlayOpacity
				paddingBottom
				paddingTop
				text
				buttonLink {
					url
				}
				image {
					altText
					sourceUrl
					localFile {
						childImageSharp {
							fluid(quality: 90, maxWidth: 1920) {
								base64
								aspectRatio
								src
								srcSet
								srcWebp
								srcSetWebp
								sizes
							}
						}
					}
				}
			}
		}
	}
  
      }
     
 
      ... on WpPage_PageComponents_Components_BlockColumns {
        
		column1
		column2
		paddingBottom
		paddingTop
  
      }
     
 
      ... on WpPage_PageComponents_Components_BlockDriversExperience {
        
		buttonText
		link {
			url
			target
			title
		}
		showReadmoreButton
		maximumPostsCount
		paddingTop
		paddingBottom
	
      }
     
 
      ... on WpPage_PageComponents_Components_BlockForm {
        
		formId
		paddingTop
		paddingBottom
  
      }
     
 
      ... on WpPage_PageComponents_Components_BlockIcons {
        
		paddingTop
		paddingBottom
		backgroundColor
		iconLeft
		blocks {
			blockIconsIcon
			blockIconsTitle
			blockIconsContent
		}
  
      }
     
 
      ... on WpPage_PageComponents_Components_BlockImageTextRow {
        
		paddingTop
		paddingBottom
		blocks {
			image {
				altText
				sourceUrl
				localFile {
				  childImageSharp {
					gatsbyImageData(width: 800)
				  }
				}
			  }
            title
            subtitle
            text
		}
  
      }
     
 
      ... on WpPage_PageComponents_Components_BlockPerformanceTable {
        
	paddingBottom
	variant
	paddingTop
  
      }
     
 
      ... on WpPage_PageComponents_Components_BlockRecentlyTuned {
        
		buttonText
		link {
			url
			target
			title
		}
		showReadmoreButton
		maximumPostsCount
		paddingTop
		paddingBottom
	
      }
     
 
      ... on WpPage_PageComponents_Components_BlockTextIconBlocks {
        
		text
		active
		paddingBottom
		linkOrButton
		paddingTop
		title
		link {
			url
			target
			title
		}
		linkButtonText
		icons {
			fieldGroupName
			iconTitle
			fontawesomeIconName
		}
  
      }
     
 
      ... on WpPage_PageComponents_Components_BlockTextImage {
        
		imagePosition
		linkOrButton
		active
		linkButtonText
		paddingBottom
		paddingTop
		text
		title
		subtitle
		titleColor
		h1Tag
		link {
			url
			target
			title
		}
		backgroundShape
		image {
			altText
			sourceUrl
			localFile {
			  childImageSharp {
				gatsbyImageData(width: 800)
			  }
			}
		  }
	
      }
     
 
      ... on WpPage_PageComponents_Components_BlockTitle {
        
		backgroundShape
		paddingBottom
		fieldGroupName
		paddingTop
		subtext
		text
  
      }
     
 
      ... on WpPage_PageComponents_Components_BlockTuningLocations {
        
		maximumPostsCount
		paddingTop
		paddingBottom
	
      }
    
      }
    }
  
        }
      }
    `
  
